import store from "@/store"
import Repository from "@/repositories/api.js"
const resource = "/admin/support_organizations"

export default {
  async getAll(params) {
    let response = await Repository.get(resource, {
      params: { ...params, ...{ region_id: store.state.auth.chosenRegion } }
    })
    return response.data
  },
  async get(organizationID) {
    let response = await Repository.get(`${resource}/${organizationID}`)
    return response.data.support_organization
  },
  async create(organizationData) {
    let response = await Repository.post(`${resource}`, {
      region_id: store.state.auth.chosenRegion,
      support_organization: organizationData
    })
    return response.data
  },
  async update(organizationID, organizationData) {
    let response = await Repository.patch(`${resource}/${organizationID}`, {
      support_organization: organizationData
    })
    return response.data
  },
  async destroy(organizationID) {
    let response = await Repository.delete(`${resource}/${organizationID}`)
    return response.data
  },
  async approve(organizationID) {
    let response = await Repository.patch(
      `${resource}/${organizationID}/approve`
    )
    return response.data
  },
  async reject(organizationID) {
    let response = await Repository.patch(
      `${resource}/${organizationID}/reject`
    )
    return response.data
  }
}
