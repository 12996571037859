import store from "@/store"
import Repository from "@/repositories/api.js"
const resource = "/admin/institutions"

export default {
  async getAll() {
    let response = await Repository.get(resource, {
      params: { region_id: store.state.auth.chosenRegion }
    })
    return response.data.institutions
  },
  async get(institutionID) {
    let response = await Repository.get(`${resource}/${institutionID}`)
    return response.data.institution
  },
  async create(institutionData) {
    let response = await Repository.post(`${resource}`, {
      region_id: store.state.auth.chosenRegion,
      institution: institutionData
    })
    return response.data
  },
  async update(institutionID, institutionData) {
    let response = await Repository.patch(`${resource}/${institutionID}`, {
      institution: institutionData
    })
    return response.data
  },
  async destroy(institutionID) {
    let response = await Repository.delete(`${resource}/${institutionID}`)
    return response.data
  },
  async sort(institutions) {
    let response = await Repository.patch(`${resource}/sort`, {
      institutions: institutions
    })
    return response.data
  },
  async emailExport() {
    let response = await Repository.get(`${resource}/email_export`, {
      params: { region_id: store.state.auth.chosenRegion }
    })
    return response.data.institutions
  }
}
