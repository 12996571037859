<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Export E-Mail-Adressen">
      <template #actions>
        <region-chooser
          v-if="$store.getters['auth/isSuperAdmin']"
          @region:chosen="loadEmails"
        ></region-chooser>
      </template>
      <el-card v-if="clickedUnderstand">
        <div slot="header">
          <span
            >E-Mail-Adressen aller <strong>Veranstalter</strong> für Ihr
            E-Mail-Programm</span
          >
          <el-button
            v-clipboard:copy="OrganizationsEmailString"
            v-clipboard:success="onCbCopy"
            v-clipboard:error="onCbError"
            style="float: right; padding: 3px 0"
            type="text"
          >
            <i class="fa-copy fal"></i>
            In Zwischenablage kopieren
          </el-button>
        </div>
        <div>
          <el-select v-model="separator" placeholder="Auswählen">
            <el-option
              key="comma"
              label="getrennt durch Komma"
              value=", "
            ></el-option>
            <el-option
              key="semicolon"
              label="getrennt durch Strichpunkt"
              value="; "
            ></el-option>
          </el-select>
        </div>
        <p style="line-height: 1.4;">{{ OrganizationsEmailString }}</p>
      </el-card>

      <el-card v-if="clickedUnderstand" style="margin-top: 24px;">
        <div slot="header">
          <span
            >E-Mail-Adressen aller <strong>Einrichtungen</strong> für Ihr
            E-Mail-Programm</span
          >
          <el-button
            v-clipboard:copy="InstitutionsEmailString"
            v-clipboard:success="onCbCopy"
            v-clipboard:error="onCbError"
            style="float: right; padding: 3px 0"
            type="text"
          >
            <i class="fa-copy fal"></i>
            In Zwischenablage kopieren
          </el-button>
        </div>
        <div>
          <el-select v-model="separator" placeholder="Auswählen">
            <el-option
              key="comma"
              label="getrennt durch Komma"
              value=", "
            ></el-option>
            <el-option
              key="semicolon"
              label="getrennt durch Strichpunkt"
              value="; "
            ></el-option>
          </el-select>
        </div>
        <p style="line-height: 1.4;">{{ InstitutionsEmailString }}</p>
      </el-card>
      <el-row v-else :gutter="24">
        <el-col :span="12">
          <el-alert
            title="Hinweis zum Datenschutz"
            type="warning"
            description="Aus Datenschutzgründen sollten Sie die
            E-Mail-Adressen der Veranstalter bzw. Einrichtungen nie einfach
            in das 'An'-Eingabefeld Ihres E-Mail-Programms einfügen."
            show-icon
          >
          </el-alert>
          <p>
            Wir empfehlen stattdessen die Nutzung des "BCC" Felds in Ihrem
            E-Mail-Programm. So wird sichergestellt, dass ein Empfänger Ihrer
            E-Mail nicht automatisch auch alle anderen Empfängeradressen sehen
            kann. Mehr Infos finden Sie
            <a target="_blank" href="https://blindkopie.de/">hier</a>.
          </p>
          <p>
            <strong>Wichtig: </strong> Lassen Sie nicht das "An"-Feld frei, wenn
            sie BCC für die Empfänger nutzen. Tragen Sie hier z.B. Ihre eigene
            E-Mail-Adresse oder eine Funktionsadresse
            ("familienbildung@musterkommune.de") ein.
          </p>

          <el-button type="primary" @click="clickedUnderstand = true"
            >Verstanden</el-button
          >
        </el-col>
      </el-row>
    </page-content>
  </div>
</template>

<script>
import SupportOrganizationsRepository from "@/repositories/support_organizations_repository.js"
import InstitutionsRepository from "@/repositories/institutions_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import RegionChooser from "@/components/RegionChooser"

export default {
  metaInfo: {
    title: "Export E-Mail-Adressen"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    RegionChooser
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      loading: true,
      organizations: [],
      institutions: [],
      clickedUnderstand: false,
      separator: ", "
    }
  },
  computed: {
    OrganizationsEmailString() {
      return this.organizations
        .map(function(o) {
          return o.email
        })
        .join(this.separator)
    },
    InstitutionsEmailString() {
      let emails = this.institutions.map(function(i) {
        return i.email
      })
      var deduped = Array.from(new Set(emails))
      return deduped.join(this.separator)
    }
  },
  async created() {
    this.loadEmails()
  },
  methods: {
    async loadEmails() {
      this.loading = true

      try {
        let orgData = await SupportOrganizationsRepository.getAll({
          show_all: true
        })
        let institutionsData = await InstitutionsRepository.emailExport()
        this.organizations = orgData.support_organizations
        this.institutions = institutionsData
        this.loading = false
      } catch (error) {
        this.loading = false
        this.handleApiError(error)
      }
    },
    onCbCopy() {
      this.$message({
        message: `E-Mail-Adressen wurden in die Zwischenablage kopiert.`,
        type: "success",
        showClose: true
      })
    },
    onCbError() {
      this.$message({
        message: `Fehler beim Kopieren in die Zwischenablage. Bitte kopieren Sie die Adressen manuell.`,
        type: "error",
        showClose: true
      })
    }
  }
}
</script>
